<template>
  <div>
    <div class="d-flex justify-content-between mb-3">
      <h2>Список видов</h2>
      <UIButton text="Добавить" leftIcon="bi-plus" @click="addNewKind" />
    </div>
    <div class="mb-3">
      <UISelect
        v-model="selectKind"
        :options="allKind"
        valueKey="modelName"
        textKey="title"
        :withGroups="true"
        editable
      />
    </div>

    <UITable v-bind="kindTable" @rowClick="showKindDetails" />
  </div>
</template>

<script>
import {
  API_GetAllEnumKindsInModel,
  API_GetAllKinds,
  API_GetAllKindsInModel,
} from "@/services/kind";
import UISelect from "@/components/UI/CustomSelect";
import UITable from "@/components/Table";
import UIButton from "@/components/UI/Button";

export default {
  name: "KindAlList",
  components: { UISelect, UITable, UIButton },
  data() {
    return {
      allKind: [],
      selectKind: null,
      kindTable: {
        test: null,
        items: [],
        searchBy: "id",
        headers: [
          { text: "№", value: "id", sorting: true },
          { text: "Наименование", value: "name", sorting: true },
          { text: "Значение", value: "value" },
          { text: "Описание", value: "description" },
          { text: "Код", value: "code" },
        ],
      },
    };
  },
  mounted() {
    API_GetAllKinds()
      .then((res) => {
        this.allKind = res;
      })
      .catch(() => {
        this.$notify({
          type: "error",
          duration: 10000,
          text: "Неудалось получить список моделей",
        });
      });
  },
  methods: {
    async loadTable(model) {
      if (model.enumKind) {
        API_GetAllEnumKindsInModel(model.id).then((res) => {
          this.kindTable.items = res;
        });
      } else {
        API_GetAllKindsInModel(model.id).then((res) => {
          this.kindTable.items = res;
        });
      }
    },
    showKindDetails(event) {
      this.$router.push({
        path: `/references/kinds/${this.selectKind}/${event.item.id}`,
      });
    },
    addNewKind() {
      if (this.selectKind) {
        this.$router.push({
          path: `/references/kinds/${this.selectKind}/add`,
        });
      }
    },
  },
  watch: {
    selectKind: function () {
      this.allKind.forEach((group) => {
        group.items.forEach((item) => {
          if (item.id === this.selectKind) {
            return this.loadTable(item);
          }
        });
      });
    },
  },
};
</script>

<style scoped></style>
