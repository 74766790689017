import axios from "@/plugins/axios";

export const API_GetAllKinds = () => {
  return axios(`/all_kind/models`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) =>
      Array.from(response.data.data, (group) => ({
        group: group.group,
        items: Array.from(group.items, (item) => ({
          id: item.modelName,
          value: item.title,
          enumKind: item.enumKind,
        })),
      }))
    )
    .catch((error) => {
      throw error;
    });
};

export const API_GetAllKindsInModel = (modelName) => {
  return axios(`/all_kind/model/${modelName}?perPage=10000`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      let parseData = [];
      response.data.data.forEach((element) => {
        parseData.push({
          id: element.id,
          value: element.value,
          name: element.ru_value,
          description: element.description,
          code: element.enpro_code,
        });
      });
      return parseData;
    })
    .catch((error) => {
      throw error;
    });
};

export const API_AllKindsInModelCreate = (modelName, modelData) => {
  return axios(`/all_kind/model/${modelName}/`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: {
      value: modelData.value,
      ru_value: modelData.name,
      description: modelData.description,
      enpro_code: modelData.code,
    },
  })
    .then((response) => response.data.data)
    .catch((error) => {
      throw error;
    });
};

export const API_AllKindsInModelID = (modelName, id) => {
  return axios(`/all_kind/model/${modelName}/id/${id}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      const element = response.data.data;
      return {
        id: element.id,
        value: element.value,
        name: element.ru_value,
        description: element.description,
        code: element.enpro_code,
      };
    })
    .catch((error) => {
      throw error;
    });
};

export const API_AllKindsInModelIDPUT = (modelName, id, modelData) => {
  return axios(`/all_kind/model/${modelName}/id/${id}`, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
    },
    data: {
      value: modelData.value,
      ru_value: modelData.name,
      description: modelData.description,
      enpro_code: modelData.code,
    },
  })
    .then((response) => response.data.data)
    .catch((error) => {
      throw error;
    });
};

export const API_AllKindsInModelIDDELETE = (modelName, id) => {
  return axios(`/all_kind/model/${modelName}/id/${id}`, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => response.data.data)
    .catch((error) => {
      throw error;
    });
};

export const API_GetAllEnumKindsInModel = (modelName) => {
  return axios(`/all_enum_kind/model/${modelName}?perPage=1000`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      let parseData = [];
      response.data.data.forEach((element) => {
        parseData.push({
          id: element.id,
          value: element.literal,
          name: element.ru_value,
          description: element.description,
          code: element.enpro_code,
        });
      });
      return parseData;
    })
    .catch((error) => {
      throw error;
    });
};
