<template>
  <div>
    <!-- Title -->
    <h3 v-if="title" class="table-title">{{ title }}</h3>
    <!-- Search bar -->
    <div v-if="searchBy !== null" class="search-bar">
      <input
        class="form-control"
        type="text"
        v-model.trim="search"
        placeholder="Поиск по наименованию"
      />
      <button
        v-if="search.length > 0"
        type="button"
        class="position-absolute search-bar-reset-btn"
        @click="search = ''"
      >
        <i class="bi-x-circle"> </i>
      </button>
    </div>

    <!-- Table -->
    <div class="table-wrapper table-auto-height">
      <table class="custom-table table-hover">
        <thead>
          <tr>
            <!-- Check box header -->
            <th v-if="checked">
              <input
                class="form-check-input"
                type="checkbox"
                @input="checkAll($event.target.checked)"
                id="rowCheck"
                :checked="checked.length === items.length"
              />
            </th>
            <!-- Index column header -->
            <th v-if="showIndex">№</th>

            <!-- Headers -->
            <th
              v-for="(item, index) in headers"
              :key="item.text"
              class="no-wrap text-center"
            >
              <span
                v-if="item.sorting === true"
                @click="setSorting(index)"
                class="sortable"
              >
                {{ item.text }}
              </span>
              <span v-else>
                {{ item.text }}
              </span>

              <i
                v-if="sorting.col === index"
                :class="sorting.isDesc ? 'bi-arrow-down' : 'bi-arrow-up'"
              ></i>
            </th>

            <!-- Delete button column header -->
            <th v-if="editable && itemsToShow.length > 0"></th>
          </tr>
        </thead>

        <tbody>
          <tr v-show="itemsToShow.length === 0">
            <td colspan="100%" class="text-center">Данных нет</td>
          </tr>
          <tr v-for="(item, index) in itemsToShow" :key="item.id">
            <!-- Check box column -->
            <td v-if="checked" @click="$emit('rowClick', { index, item })">
              <input
                class="form-check-input"
                type="checkbox"
                :value="index"
                v-model="currentChecked"
                id="rowCheck"
              />
            </td>
            <!-- Index column -->
            <td v-if="showIndex" @click="$emit('rowClick', { index, item })">
              {{ index + 1 }}
            </td>

            <td
              v-for="header in headers"
              :key="header.text"
              @click="$emit('rowClick', { index, item })"
            >
              <!-- Plant text -->
              <template v-if="!header.type || header.type === FieldType.text">
                {{ item[header.value] }}
              </template>

              <!-- Editable text -->
              <template v-else-if="header.type === FieldType.editText">
                <EditableInput v-model="item[header.value]" />
              </template>

              <!-- Editable number -->
              <template v-else-if="header.type === FieldType.editNumber">
                <EditableNumber v-model="item[header.value]" />
              </template>

              <!-- Selection -->
              <template v-else-if="header.type === FieldType.select">
                <Select
                  v-model="item[header.value].current"
                  :options="
                    item[header.value].options
                      ? item[header.value].options
                      : header.default
                  "
                />
              </template>

              <!-- Selection R -->
              <template v-else-if="header.type === FieldType.selectR">
                <RSelect
                  v-model="item[header.value]"
                  :options="header.options"
                  editable
                />
              </template>

              <!-- Icon -->
              <template v-else-if="header.type === FieldType.icon">
                <i
                  :class="item[header.value].icon"
                  :style="item[header.value].style"
                ></i>
              </template>
            </td>

            <!-- Delete row button -->
            <td
              v-if="editable"
              class="delete-row-btn"
              @click="deleteItem(index)"
            >
              <i class="bi-trash-fill"></i>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- The edit controls -->
      <div v-if="editable" class="table-add">
        <!-- Add from available -->
        <template v-if="availableAddings">
          <template v-if="availableToAdd.keys.length > 0">
            <div v-if="!addMode" @click="addMode = true">
              <i class="bi-plus-square"></i>
            </div>
            <div v-else id="select-box">
              <Select v-model="itemToAdd" :options="availableToAdd.keys" />
              <div class="button-holder">
                <button @click="addItem">
                  <i class="bi-check2"></i>
                </button>
                <button @click="addMode = false">
                  <i class="bi-x"></i>
                </button>
              </div>
            </div>
          </template>
          <div v-else>Больше нечего добовлять</div>
        </template>

        <!-- Create new row -->
        <div v-else @click="addNewRow">
          <i class="bi-plus-square"></i>
        </div>
      </div>
    </div>

    <!-- Footer bar -->
    <div class="footer-bar d-flex justify-content-between">
      <div></div>
      <div>
        <ul class="pagination mb-0" v-show="sortedItems.length > pageSize">
          <li class="page-item" :class="{ disabled: pageNumber === 1 }">
            <a class="page-link" style="cursor: pointer" @click="previousPage"
              >Назад</a
            >
          </li>

          <li
            class="page-item"
            v-for="page in paginatedPages.pages"
            :class="{ active: page === pageNumber }"
            :key="page"
          >
            <a
              class="page-link"
              style="cursor: pointer"
              @click="setPage(page)"
              >{{ page }}</a
            >
          </li>

          <li
            class="page-item"
            :class="{ disabled: pageNumber === paginatedPages.totalPages }"
          >
            <a class="page-link" style="cursor: pointer" @click="nextPage"
              >Вперед</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import EditableInput from "@/components/UI/EditableInput";
import EditableNumber from "@/components/UI/EditableNumber.vue";
import Select from "@/components/UI/Select.vue";
import RSelect from "@/components/UI/CustomSelect";

export const FieldType = Object.freeze({
  text: Symbol("text"),
  editText: Symbol("editText"),
  editNumber: Symbol("editNumber"),
  select: Symbol("select"),
  selectR: Symbol("selectR"),
  icon: Symbol("icon"),
});

export default {
  components: { EditableInput, EditableNumber, Select, RSelect },
  props: {
    showIndex: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    checked: Array,
    availableAddings: Object,
    searchBy: {
      type: String,
      default: null,
    },
    title: String,
    headers: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      FieldType,
      search: "",
      sorting: { col: undefined, isDesc: false },
      addMode: false,
      itemToAdd: 0,
      currentChecked: this.checked,
      pageSize: 10,
      pageNumber: 1,
    };
  },
  methods: {
    setSorting(col_index) {
      if (this.sorting.col !== col_index) {
        this.sorting.col = col_index;
        this.sorting.isDesc = false;
      } else this.sorting.isDesc = !this.sorting.isDesc;
    },
    addItem() {
      const target = this.availableToAdd.filtered[this.itemToAdd];
      this.items.push(this.availableAddings.items.indexOf(target));
      this.itemToAdd = 0; // Reset index to zero
    },
    deleteItem(index) {
      this.items.splice(index, 1);
    },
    addNewRow() {
      let newItem = {};
      for (const header of this.headers) {
        switch (header.type) {
          case this.FieldType.select:
            newItem[header.value] = { current: 0, options: header.default };
            break;
          case this.FieldType.selectR:
            newItem[header.value] = header.options[0].id;
            break;
          case this.FieldType.editNumber:
            newItem[header.value] = header.default ? header.default : 0;
            break;
          default:
            newItem[header.value] = header.default ? header.default : "";
            break;
        }
      }
      this.items.push(newItem);
    },
    getHeaderKey(headerIndex) {
      return this.headers[headerIndex].value;
    },
    checkAll(checked) {
      if (checked) {
        const result = new Array(this.items.length);
        for (let i = 0; i < result.length; i++) {
          result[i] = i;
        }
        this.currentChecked = result;
      } else {
        this.currentChecked = [];
      }
    },
    nextPage() {
      this.pageNumber += 1;
    },
    previousPage() {
      this.pageNumber -= 1;
    },
    setPage(value) {
      this.pageNumber = value;
    },
  },
  computed: {
    availableToAdd() {
      if (this.availableAddings) {
        const filtered = this.availableAddings.items.filter(
          (element, index) => !this.items.includes(index)
        );
        return {
          keys: Array.from(
            filtered,
            (element) => element[this.availableAddings.key]
          ),
          filtered,
        };
      } else return undefined;
    },
    itemsToShow() {
      return this.paginatedData;
    },
    paginatedData() {
      return this.sortedItems.slice(
        (this.pageNumber - 1) * this.pageSize,
        this.pageNumber * this.pageSize
      );
    },
    paginatedPages() {
      let maxPages = 5;
      let totalPages = Math.ceil(this.sortedItems.length / this.pageSize);

      let startPage, endPage;
      if (totalPages <= maxPages) {
        startPage = 1;
        endPage = totalPages;
      } else {
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (this.pageNumber <= maxPagesBeforeCurrentPage) {
          startPage = 1;
          endPage = maxPages;
        } else if (this.pageNumber + maxPagesAfterCurrentPage >= totalPages) {
          startPage = totalPages - maxPages + 1;
          endPage = totalPages;
        } else {
          startPage = this.pageNumber - maxPagesBeforeCurrentPage;
          endPage = this.pageNumber + maxPagesAfterCurrentPage;
        }
      }
      let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
        (i) => startPage + i
      );
      return {
        totalPages: totalPages,
        pages: pages,
      };
    },
    sortedItems() {
      if (this.sorting.col) {
        let items = this.filteredBySearch;
        const key = this.getHeaderKey(this.sorting.col);
        return items.sort((a, b) => {
          if (this.sorting.isDesc) b = [a, (a = b)][0]; // Swap variables
          if (a[key] < b[key]) return -1;
          if (a[key] > b[key]) return 1;
          return 0;
        });
      } else return this.filteredBySearch;
    },
    filteredBySearch() {
      const items = this.availableAddings
        ? Array.from(this.items, (index) => this.availableAddings.items[index])
        : this.items;
      if (this.searchBy !== null && this.search !== "") {
        const reg = new RegExp(`^.*${this.search}.*$`);
        return items.filter((row) => reg.test(row[this.searchBy]));
      } else return items;
    },
  },
  watch: {
    currentChecked(new_) {
      this.$emit("update:checked", new_);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-title {
  margin: 0;
  padding: 6px 30px;
  background-color: $main-3;
}

//Search
.search-bar {
  background-color: $main-3;
  display: flex;
  padding: 10px 20px;
  position: relative;

  .form-control {
    flex: 1;
    height: 42px;
  }

  .search-bar-reset-btn {
    border-radius: $border-radius;
    background-color: $highlight;
    height: 42px;
    right: 20px;
    padding: 10px !important;
    min-width: auto !important;
    border: none !important;
  }
}
.footer-bar {
  background-color: $main-3;
  //display: flex;
  padding: 10px 20px;
  position: relative;
}

// Table
.custom-table {
  margin-bottom: 0;
  color: white;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  .delete-row-btn {
    padding: 10px;
    font-size: 1.3em;
    width: 0;
  }

  .btn-default {
    background-color: $main-3;
    color: white;
    border: 0;

    &:hover {
      background-color: $main-3;
      color: white;
      border: 0;
      opacity: 0.8;
    }
  }

  select.form-control {
    background-color: $main-3;
    color: white;
    border: 0;
    border-radius: $border-radius;
  }

  th,
  td {
    border: none;
    cursor: default;
    &:first-child {
      .checkbox {
        padding-left: 20px;
        margin-bottom: 0;
      }
    }
  }
  th .sortable {
    text-decoration: underline;
    cursor: pointer;
  }

  .nameTd {
    width: 40%;
  }

  thead {
    th {
      background-color: $main-1;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      padding: 20px 20px;
      color: white;
      box-sizing: border-box;
      border-bottom: 0;
      vertical-align: middle;

      &:not(:first-child) {
        border-left: 1px solid #18191d;
      }
    }

    .form-control {
      display: inline-block;
      width: auto;
    }

    .no-wrap .form-control {
      margin-left: 10px;
    }
  }

  a {
    text-decoration: none;
  }

  .color-link {
    text-decoration: underline;
    color: $highlight;
    white-space: nowrap;

    &:hover {
      text-decoration: none;
    }
  }

  tbody {
    tr {
      background-color: $main-1;
      &:not(:first-child):hover {
        background-color: $main-2;
      }
    }
    td {
      border-top: 1px solid #292b2f;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      padding: 20px 20px;
      color: #fff;
      box-sizing: border-box;
      vertical-align: middle;

      &:not(:first-child) {
        border-left: 1px solid #292b2f;
        cursor: pointer;
      }
    }
  }
}

.table-add {
  background-color: $main-1;
  padding: 10px 20px;
  text-align: center;
  box-shadow: inset #02010240 0px 4px 10px 0px;

  #select-box {
    position: relative;

    .form-select {
      border: solid 2px $main-3;
    }

    .button-holder {
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
      border-radius: 0 $border-radius $border-radius 0;
      overflow: hidden;

      button {
        font-size: 20px;
        height: 100%;
        background-color: $main-3;
        color: white;
        border-radius: 0;

        &:not(:first-child) {
          border-left: solid 1px $main-2;
        }
        &:hover {
          background-color: $main-2;
        }
      }
    }
  }
}

.table-wrapper {
  overflow: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 13px;
    height: 13px;
    background-color: #2f3136;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #202225;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iNzY3IiB2aWV3Qm94PSIwIDAgMTQgNzY3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KICAgIDxsaW5lIHgxPSIyLjg2NDI2IiB5MT0iMC41IiB4Mj0iMTAuMTM3IiB5Mj0iMC41IiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4NCiAgICA8bGluZSB4MT0iMi44NjQyNiIgeTE9IjUuNSIgeDI9IjEwLjEzNyIgeTI9IjUuNSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+DQogICAgPGxpbmUgeDE9IjE0IiB5MT0iNzY2LjUiIHkyPSI3NjYuNSIgc3Ryb2tlPSIjMTYxNzE5Ii8+DQo8L3N2Zz4NCg==");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 10px 10px;

    &:hover {
      background-color: #222427;
    }
  }
}

.table-bottom-bar {
  padding: 15px 20px;
  background: $main-3;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left,
  .right {
    display: flex;
  }
}
</style>
