<template>
  <div class="editable-field-number">
    <template v-if="editMode">
      <input
        ref="inputField"
        v-model="temp_value"
        type="number"
        class="form-control"
        @blur="disableEdit"
      />
    </template>
    <div v-else v-on="editable ? { dblclick: enableEdit } : {}">
      {{ value }}
      <i
        v-if="editable"
        class="bi-pen"
        title="Кликните дважды, чтобы изменить"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      temp_value: 0,
      editMode: false,
    };
  },
  methods: {
    enableEdit() {
      this.temp_value = this.value;
      this.editMode = true;
      this.$nextTick(() => this.$refs.inputField.focus());
    },
    disableEdit() {
      if (this.temp_value != "") {
        this.$emit("input", parseFloat(this.temp_value));
      }
      this.editMode = false;
    },
  },
};
</script>

<style lang="scss">
.editable-field-number i {
  float: right;
}
</style>
