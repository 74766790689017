<template>
  <div>
    <label
      v-if="label"
      for="selectLabel"
      class="form-label"
      v-text="label"
    ></label>

    <select
      v-if="editable"
      class="form-select custom-input"
      id="selectLabel"
      v-model="lValue"
      :disabled="!editable"
    >
      <option :value="null">---</option>
      <template v-if="withGroups">
        <optgroup v-for="(group, id) in options" :label="group.group" :key="id">
          <option
            v-for="option in group.items"
            :key="option.id"
            :value="option.id"
          >
            {{ option.value }}
          </option>
        </optgroup>
      </template>
      <template v-else>
        <option v-for="option in options" :key="option.id" :value="option.id">
          {{ option.value }}
        </option>
      </template>
    </select>
    <input
      v-else
      type="text"
      class="form-control custom-input"
      id="inputLabel"
      :value="lValue"
      readonly
    />
  </div>
</template>

<script>
export default {
  props: {
    path: String,
    value: [Object, String, Number, Array],
    options: Array,
    editable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
    withGroups: {
      type: Boolean,
      default() {
        return false;
      },
    },
    label: String,
  },
  computed: {
    lValue: {
      get: function () {
        if (this.options) {
          return this.value;
        }
        return null;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style lang="scss">
.custom-input:read-only {
  background-color: $main-1;
}
</style>
