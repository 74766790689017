<template>
  <select
    v-if="editable"
    class="form-select"
    v-bind:value="value"
    @input="$emit('input', parseInt($event.target.value))"
  >
    <option v-for="(option, index) in options" :key="option" :value="index">
      {{ option }}
    </option>
  </select>
  <span v-else>{{ options[value] }}</span>
</template>

<script>
export default {
  props: {
    value: Number,
    options: Array,
    editable: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style></style>
